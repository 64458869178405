import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Label,
  FormGroup
} from 'reactstrap';
import { toast } from 'react-toastify';
import { TimePicker } from 'antd';
import moment from 'moment';
import { updatePrebookStatus, cancelRequest } from '../../services/api/offline-consult';


const PrebookUserConfirmed = ({
  isOpen,
  closePrebookUserConfirmModal,
  getAllOfflineRequests,
  prebookRequestDetails,
}) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [appDate, setAppDate] = useState('');
  const [followUpTime, setFollowUpTime] = useState('');
  const [followUpRequiredFrom, setFollowUpRequiredFrom] = useState('User');

  const FOLLOWUP_DROPDOWN_OPTIONS = ['User', 'Centre', 'Network Team'];

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedBox(value);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;

    if (!selectedDate) {
      return;
    }

    const currentDate = moment().format('YYYY-MM-DD');

    if (selectedDate >= currentDate) {
      setAppDate(selectedDate);
    } else {
      toast.warning('Please select a date equal to or after the current date.');
    }
  };

  const disabledTime = (current, type) => {
    const currentMoment = moment();

    if (type === 'hour' && currentMoment) {
      // Disable hours before the current hour
      return {
        disabledHours: () => [...Array(currentMoment.hours()).keys()],
      };
    }

    if (type === 'minute' && currentMoment) {
      // Disable minutes before the current minute
      return {
        disabledMinutes: () => [...Array(currentMoment.minutes()).keys()],
      };
    }

    return {};
  };

  const handleDropDownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCancelReasonChange = (event) => {
    setCancelReason(event.target.value);
  };

  const handlePrebookUserConfirmed = () => {
    setLoading(true);
    const { requestId } = prebookRequestDetails;
    let body = {};
    if(selectedBox == 'Yes' && prebookRequestDetails.center_type == 'prepaid') {
        body.status = 'user-confirmed';
    } else if(selectedBox == 'Yes' && prebookRequestDetails.center_type != 'prepaid') {
        body.status = 'confirmed';
    } else if(selectedBox == 'No' && selectedOption == '1') {
        body.status = 'on-hold';
        if (!appDate || !followUpTime || !followUpRequiredFrom) {
            toast.error('Select follow Date, time and required type');
            return;
        } 
        body.followUpDate = appDate;
        body.followUpTime = followUpTime;
        body.followUpRequiredFrom = followUpRequiredFrom;
    } else if(selectedBox == 'No' && selectedOption == '2') {
        body.status = 'cancelled';
        if(cancelReason == 'Others' && !remarks) {
            toast.error('Please enter a remarks');
            return;
        }
    }
    try {
      const prebookUpdateStatusCall = body.status == 'cancelled' ? cancelRequest(requestId, cancelReason, remarks, 0, null, remarks || cancelReason) : updatePrebookStatus(requestId, body);
      return prebookUpdateStatusCall
        .then((res) => {
          if (res.message === 'success') {
            toast.success(`Success`);
            setLoading(false);
            closePrebookUserConfirmModal();
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };
console.log(prebookRequestDetails,"detailsss");

return (
    <Modal
      isOpen={isOpen}
      toggle={closePrebookUserConfirmModal}
      centered
    >
      <ModalHeader className="revamp-modal-header" toggle={closePrebookUserConfirmModal}>
        Prebook User Confirmation
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <label style={{ fontFamily: 'Inter, sans-serif', width: '100%', color: 'red' }}>
              {`This is a Prebook Appointment for ${prebookRequestDetails && prebookRequestDetails.center_type == 'prepaid' ? 'Prepaid' : 'Credit'} Center.`}
            </label>
          </Row>
          <Row>
            {selectedBox != 'No' && (
              <Col>
                <label
                  style={{ fontFamily: 'Inter, sans-serif', width: '100%' }}
                >
                  Was the appointment self-booked by the user?
                </label>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                  <label>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={selectedBox === 'Yes'}
                      onChange={handleCheckboxChange}
                      style={{marginRight: '10px'}}
                    />
                    Yes
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      value="No"
                      checked={selectedBox === 'No'}
                      onChange={handleCheckboxChange}
                      style={{marginRight: '10px'}}
                    />
                    No
                  </label>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            {selectedBox == 'No' && (
              <div>
                <label>
                  Choose an option:
                  <select
                    value={selectedOption}
                    onChange={handleDropDownChange}
                    style={{marginLeft: '24px'}}
                  >
                    <option value="">--Select--</option>
                    <option value="1">
                      User is seeking a booking from the centre
                    </option>
                    <option value="2">Cancel the appointment</option>
                  </select>
                </label>
              </div>
            )}
          </Row>
          <Row>
            {selectedOption == '1' && (
              <form>
                <FormGroup>
                  <Row>
                    <Col className="col-3">
                      <Label>FollowUp Date:</Label>
                    </Col>
                    <Col>
                      <input
                        className="form-control"
                        type="date"
                        value={appDate}
                        onChange={handleDateChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col className="col-3">
                      <Label>FollowUp Time:</Label>
                    </Col>
                    <Col>
                      <TimePicker
                        className="w-full"
                        //   disabledTime=(current) =>
                        //     disabledTime(current, 'hour')
                        disabledTime={(current) =>
                          disabledTime(current, 'hour')
                        }
                        onChange={(_, date) => {
                          setFollowUpTime(date);
                        }}
                        format="hh:mm:00 A"
                        defaultValue={
                          followUpTime
                            ? moment(followUpTime, 'hh:mm:00 A')
                            : undefined
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col className="col-3">
                      <Label>Follow Up required from:</Label>
                    </Col>
                    <Col>
                      <select
                        className="form-control"
                        value={followUpRequiredFrom}
                        onChange={(e) => {
                          setFollowUpRequiredFrom(e.target.value);
                        }}
                      >
                        {FOLLOWUP_DROPDOWN_OPTIONS.map((dropdownOption) => (
                          <option>{dropdownOption}</option>
                        ))}
                      </select>{' '}
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            )}
            {selectedOption == '2' && (
              <div>
                <label>
                  Cancel Reason:
                  <select
                    value={cancelReason}
                    onChange={handleCancelReasonChange}
                    style={{marginLeft: '40px'}}
                  >
                    <option value="">--Select--</option>
                    <option value="User will book a new appointment via Visit">
                      User will book a new appointment via Visit
                    </option>
                    <option value="User doesn’t want an appointment">
                      User doesn’t want an appointment
                    </option>
                    <option value="User created booking for demo">
                      User created booking for demo
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </label>
                {cancelReason == 'Others' && (
                  <div>
                    <label>
                      Others:{' '}
                      <input
                        type="text"
                        value={remarks}
                        onChange={(event) => setRemarks(event.target.value)}
                        style={{borderRadius: '8px', width : '300px', marginLeft: '80px'}}
                      />
                    </label>
                  </div>
                )}
              </div>
            )}
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          style={{
            backgroundColor: 'rgb(113, 79, 255)',
            width: '40%',
            borderColor: 'rgb(113, 79, 255)',
            height: '44px',
          }}
        //   disabled={}
          onClick={() => handlePrebookUserConfirmed()}
        >
          Confirm
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            width: '40%',
            borderColor: 'rgb(113, 79, 255)',
            color: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          onClick={() => closePrebookUserConfirmModal()}
        >
          Close
        </Button>
      </ModalFooter>

    </Modal>
  );
};

export default PrebookUserConfirmed;
