import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { toast } from 'react-toastify';
import {
  updatePrebookStatus,
  cancelRequest,
} from '../../services/api/offline-consult';
import { useHistory } from 'react-router-dom';

const PrebookCenterConfirmed = ({
  isOpen,
  closePrebookCenterConfirmModal,
  getAllOfflineRequests,
  prebookRequestDetails,
}) => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDropDownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePrebookCenterConfirmed = () => {
    setLoading(true);
    const { requestId } = prebookRequestDetails;
    let body = {};
    if (selectedOption == '1') {
      body.status = 'confirmed';
    } else if (selectedOption == '2' || selectedOption == '3') {
      body.status = 'cancelled';
    }
    try {
      const prebookUpdateStatusCall =
        body.status == 'cancelled'
          ? cancelRequest(
              requestId,
              'centre declined the appointment.',
              null,
              0,
              null,
              'centre declined the appointment.'
            )
          : updatePrebookStatus(requestId, body);
      return prebookUpdateStatusCall
        .then((res) => {
          if (res.message === 'success') {
            toast.success(`Success`);
            setLoading(false);
            closePrebookCenterConfirmModal();
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={closePrebookCenterConfirmModal} centered>
      <ModalHeader
        className="revamp-modal-header"
        toggle={closePrebookCenterConfirmModal}
      >
        Prebook Center Confirmation
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <label style={{ fontFamily: 'Inter, sans-serif', width: '100%' }}>
                Did the centre acknowledge the appointment booking?
              </label>
              <div>
                <label>
                  Choose an option:
                  <select
                    value={selectedOption}
                    onChange={handleDropDownChange}
                    style={{ marginLeft: '40px' }}
                  >
                    <option value="">--Select--</option>
                    <option value="1">
                      Centre is aware of the appointment
                    </option>
                    <option value="2">
                      Centre is unaware of the appointment
                    </option>
                    <option value="3">
                      Centre no longer wants to work with Visit
                    </option>
                  </select>
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          style={{
            backgroundColor: 'rgb(113, 79, 255)',
            width: '40%',
            borderColor: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          //   disabled={}
          onClick={() => handlePrebookCenterConfirmed()}
        >
          Confirm
        </Button>
        {selectedOption != '2' && (
          <Button
            style={{
              backgroundColor: 'rgb(255, 255, 255)',
              width: '40%',
              borderColor: 'rgb(113, 79, 255)',
              color: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            onClick={() => closePrebookCenterConfirmModal()}
          >
            Close
          </Button>
        )}
        {selectedOption == '2' && (
          <Button
            style={{
              backgroundColor: 'rgb(255, 255, 255)',
              width: '40%',
              borderColor: 'rgb(113, 79, 255)',
              color: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            onClick={() => {
              history.push(
                `/revamp-offline-consults/${prebookRequestDetails.requestId}`
              );
            }}
          >
            Call User
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default PrebookCenterConfirmed;
